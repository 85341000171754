import { ButtonLink, ButtonLinkProps, ButtonRow, IContract } from "@synota-io/synota-shared-ui";
import { generatePath, useMatch } from "react-router-dom";
import { CONTRACT_CREDITS_PATH, CONTRACT_PATH } from "../../../paths";
import { ContractMoreMenu } from "./ContractMoreMenu";
import { useContractActions } from "../hooks/useContractActions";

interface Props {
  contract: IContract | null;
}

const buttons = [
  {
    title: "Credits",
    path: CONTRACT_CREDITS_PATH,
  },
  {
    title: "Dashboard",
    path: CONTRACT_PATH,
  },
];

export const ContractMenu = ({ contract }: Props) => {
  const contractActions = useContractActions({ contract });

  if (!contract) {
    return null;
  }

  return (
    <ButtonRow role="menu" gap={1}>
      {buttons.map((button) => (
        <ContractMenuButton
          key={button.title}
          path={button.path}
          to={generatePath(button.path, { contractId: contract?.uuid || null })}
        >
          {button.title}
        </ContractMenuButton>
      ))}
      <ContractMoreMenu actions={contractActions} size="small" contract={contract} />
    </ButtonRow>
  );
};

const ContractMenuButton = ({ children, path, ...props }: ButtonLinkProps & { path: string }) => {
  const isActive = Boolean(useMatch({ path, end: true }));
  return (
    <ButtonLink
      role="menuitem"
      size="small"
      color={isActive ? "primary" : "neutral"}
      aria-selected={isActive}
      {...props}
    >
      {children}
    </ButtonLink>
  );
};
